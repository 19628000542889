footer {
    .link-dark {
        color: $dark;

        &:hover,
        &:focus {
            color: $primary;
        }
    }
}
